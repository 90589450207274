<div [ngClass]="{'show' : upDownClick}" class="pull-me">
    @if(upDownClick){
    <img class="position-absolute bottom-0" (click)="toggleClick()" src="../assets/images/pr-bottom.png">
    }@else {
    <img (click)="toggleClick()" src="../assets/images/pr-top.png">
    }
</div>
<div class="application">
    <div class="container-fluid p-0">
        <div [ngClass]="{'show' : upDownClick}" class="panel m-0 row align-items-center">
            @for (application of rows; track application) {
            <div class="col-12 col-sm-4">
                <div class="application-item">
                    <img class="mx-3 width-100" src="{{imageURL + application.application_module_image}}"
                        (error)="errorHandler($event)" />
                    <h5 class="text-white mb-0">{{application.application_module_name}}</h5>
                </div>
            </div>
            }
        </div>
    </div>
</div>
<div class="main">
    <div class="side-menu" [ngClass]="{'menuClose': !isMenuOpen}">
        <gps-tracker-side-nav [isMenuOpen]="isMenuOpen" (menuToggle)="menuToggle($event)" />
    </div>
    <div class="content">
        <gps-tracker-top-nav [isMenuOpen]="isMenuOpen" (menuToggle)="menuToggle($event)" />
        <div class="main-content">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<div (click)="menuToggle(false)" [ngClass]="{'drawer-overlay': isMenuOpen}"></div>