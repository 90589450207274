import { AfterViewInit, ChangeDetectorRef, Component, NgZone, afterNextRender } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { StatusBar, Style } from '@capacitor/status-bar';
//import { getMessaging, getToken } from "firebase/messaging";
//import { environment } from '../environments/environment';
//import { initializeApp } from 'firebase/app';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { SplashScreen } from '@capacitor/splash-screen';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NgxSpinnerModule, MatProgressBarModule, MatProgressSpinnerModule, CommonModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements AfterViewInit {

  isLoad = false;

  constructor(
    cdr: ChangeDetectorRef,
    private zone: NgZone
  ) {
    StatusBar.setBackgroundColor({ color: "#093f83" }).catch(() => { });
    StatusBar.setStyle({ style: Style.Dark }).catch(() => { });
    afterNextRender(() => {
      this.zone.run(() => {
        setTimeout(() => {
          if (Capacitor.getPlatform() !== 'web') {
            SplashScreen.hide();
          }
          this.isLoad = true;
          cdr.detectChanges();
        }, 100);
      });
    });
  }

  async ngAfterViewInit() {
    await SplashScreen.hide();
  }

  //firebaseApp = initializeApp(environment.firebase);

  /*ngOnInit(): void {
    if (typeof navigator !== 'undefined' && navigator) {
      this.requestPermission();
    }
  }

  requestPermission() {
    const messaging = getMessaging();
    if (messaging) {
      getToken(messaging).then(
        (currentToken) => {
          if (currentToken) {
            console.log("currentToken ", currentToken)
          }
        })
    }
  }*/
}
