import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { IMAGE_BASE_URL, LocalStorageService, SharedService } from '../../services';
import { LocalStorage } from '../../enums';
import { Subscription } from 'rxjs';
import { UserApplicationModuleDetailsData } from '../../service-proxies/models/user-application-module-details-data.model';

@Component({
  selector: 'app-app-layout',
  standalone: true,
  imports: [RouterOutlet, SharedModule, CommonModule],
  templateUrl: './app-layout.component.html',
  styleUrl: './app-layout.component.scss'
})
export class AppLayoutComponent implements OnInit {

  isReloadSubscription = new Subscription;
  isMenuOpen: boolean = true;
  currentUrl: any;
  innerWidth: number = 0;
  upDownClick: boolean = false;
  rows: any = [];
  imageURL: any = "";

  constructor(private localStorageService: LocalStorageService, private sharedService: SharedService, @Inject(IMAGE_BASE_URL) imageURL?: string) {
    this.imageURL = imageURL;
    this.rows = JSON.parse(this.localStorageService.getData(LocalStorage.userApplicationModuleDetails));

    this.isReloadSubscription = this.sharedService.getApplicationModule().subscribe((data: UserApplicationModuleDetailsData[]) => {
      this.rows = data;
    });
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.isMenuOpen = innerWidth > 991.98;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    this.isMenuOpen = innerWidth > 991.98;
  }

  errorHandler(event: any) {
    event.target.src = "assets/images/app_logo.png";
  }

  menuToggle(flag: any) {
    this.isMenuOpen = flag;
  }

  toggleClick(): void {
    this.upDownClick = !this.upDownClick;
  }

}