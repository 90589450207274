import { Routes } from '@angular/router';
import { AuthenticationLayoutComponent } from './layout/authentication-layout/authentication-layout.component';
import { DemoLayoutComponent } from './layout/demo-layout/demo-layout.component';
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { Paths } from './enums';
import { AuthGuard } from './guard';

export const routes: Routes = [
    {
        path: Paths.authentication,
        component: AuthenticationLayoutComponent,
        loadChildren: () =>
            import('./component/authentication/authentication.module').then(
                (m) => m.AuthenticationModule
            ),
    },
    {
        path: Paths.application,
        component: AppLayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./component/application/application.module').then(
                (m) => m.ApplicationModule
            )
    },
    {
        path: Paths.demo,
        component: DemoLayoutComponent,
        loadChildren: () =>
            import('./component/demo/demo.module').then(
                (m) => m.DemoModule
            )
    },
    {
        path: '',
        component: AuthenticationLayoutComponent,
        loadChildren: () =>
            import('./component/authentication/authentication.module').then(
                (m) => m.AuthenticationModule
            ),
    },
];
